import { SUPPORTED_DURATIONS, SUPPORTED_ACTIVITIES, EVENT_DATES } from "./constants";

export const INITIAL_STATE = {
  activities: [{ activity: "", duration: 30 }],
  activity_date: "",
};

export const TOOLS_CONTENT = {
  default: {
    title: 'DEFAULT TOOLS CONTENT',
    content: <div />,
    links: []
  }
}

export const I18N_STRINGS = {
  addButtonText: 'Add new item',
  removeButtonText: 'Remove',
  empty: 'No activities selected',
};