import React, { useState, useEffect } from 'react';
import { Container, Header, PieChart, Box, Button } from '@awsui/components-react';

import { SUPPORTED_ACTIVITIES, SUPPORTED_DURATIONS } from '../../../resources/constants';

const ActivityPieChart = ({ loading, series }) => {
  const [splitSeries, setSplitSeries] = useState([]);

  useEffect(
    () => {
      if (series.length === 0) return;
      parseSplitData(series);
    },
    [series]
  );

  const parseSplitData = data => {
    let output = [];
    const distinctActivities = [ ...new Set(data.map(item => item.activity))]
    distinctActivities.forEach(activity => {
      const x = data.filter(item => item.activity === activity);
      const activityName = SUPPORTED_ACTIVITIES.find(item => item.value === activity)
      const duration = x.reduce((a, b) => a + b.duration, 0)
      if (duration !== 0) output.push({
        title: !!activityName ? activityName.label : 'Other',
        value: duration
      });
    });
    setSplitSeries(output)
  }

  return (
    <Container header={<Header variant="h2">Team Activity Types</Header>}>
      <CustomPieChart items={splitSeries} loading={loading} />
    </Container>
  )
}

const CustomPieChart = ({ items, loading }) => {
  return (
    <PieChart
      data={items}
      segmentDescription={(datum, sum) => `${((datum.value / sum) * 100).toFixed(2)}%`}
      statusType={loading ? "loading" : "finished"}
      i18nStrings={{
        detailsValue: 'Value',
        detailsPercentage: 'Percentage',
        filterLabel: 'Filter displayed data',
        filterPlaceholder: 'Filter data',
        filterSelectedAriaLabel: 'selected',
        detailPopoverDismissAriaLabel: 'Dismiss',
        legendAriaLabel: 'Legend',
        chartAriaRoleDescription: 'pie chart',
        segmentAriaRoleDescription: 'segment'
      }}
      ariaDescription="Donut chart showing generic example data."
      ariaLabel="Donut chart"
      errorText="Error loading data."
      innerMetricDescription="activity types"
      innerMetricValue={items.length}
      loadingText="Loading chart"
      recoveryText="Retry"
      hideFilter={true}
      hideLegend={true}
      size="large"
      variant="donut"
      detailPopoverContent={(datum, sum) => [
        {
          key: 'Total Duration',
          value: datum.value.toLocaleString('en') + " minutes"
        },
        {
          key: 'Percentage',
          value: `${((datum.value / sum) * 100).toFixed(1)}%`
        }
      ]}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
};

export default ActivityPieChart;
