import { Auth } from '@aws-amplify/auth'
import API from '@aws-amplify/api';
// import { TEAMS } from '../../resources/constants';


const execute = true;

let state = {
    user: null,
    teams: [],
    team: {},
    userChangeSub: [],
    teamsChangeSub: []
}

const toRoute = (path) => {
    return `${path}`;
}

const subscribeUserChange = (cb) => {
    state.userChangeSub.push(cb);
}

const teamsChangeSub = (cb) => {
    state.teamsChangeSub.push(cb);
}

// Centralized user and event handler
const onUserChange = () => {
    // Loop through each callback and call it with the new user
    state.userChangeSub.forEach(cb => {
        cb(state.user);
    });
}

const onTeamsChange = () => {
    // Loop through each callback and call it with the new user
    state.teamsChangeSub.forEach(cb => {
        cb(state.teams);
    });
}

const fetchTeam = async (username) => {
    try {
        if (username.includes("_")) 
            username = username.split("_")[1]; // usernames from custom ID providers are prepended with MidwaySAML_

        const user_obj = await API.get("primary", `/users/${username}`);
        let team_obj = {};
        if (user_obj.team_id) {
            team_obj = await API.get("primary", `/teams/${user_obj.team_id}`);

            state.team = {
                //icon: require(team_obj.team_icon),
                team_id: team_obj.team_id,
                team_name: team_obj.team_name
            };    
        }
        
        if(state.user) {
            if (state.team.team_id)
                state.user.team_id = state.team.team_id;
                //state.user.team_icon = state.team.team_icon;
            state.user.team_name = state.team.team_name;
        }

        onUserChange();
    } catch (error) {
        throw error;
    }
}

const signIn = async () => {
    await Auth.federatedSignIn({ customProvider: "MidwaySAML" })
    getUser().then(_user => {
        if (!user) return;
        fetchTeam(_user.username);
    }).catch(err => console.error(err));
}

const getUser = async () => {
    if (state.user == null) {
        try {
            const userData = await Auth.currentAuthenticatedUser();
            state.user = userData;
        }
        catch (e) {
            return null
        }
    }

    return state.user;
}

const saveTeam = async () => {
    const p = [];

    if (showCreateTeam) {
        p.push(handler.createTeam(handler.teamName));
    }

    p.push(handler.assignToTeam(handler.teamName));

    let promiseExecution = async () => {
        for (let promise of p) {
            try {
                await promise;
            } catch (error) {
                console.log(error.message);
            }
        }
    };

    promiseExecution().then( p => {
        setTimeout(() => {
            resetCallback();
        }, 1000);
    });
}

const fetchTeams = async () => {
    if(state.teams.length > 0) {
        return;
    }

    try {

        const teams = await API.get("primary", `/teams/`);

        state.teams = teams.map((f) => {
            return {
                label: f.team_name,
                description: " ("+ (10 - f.team_size) + " spots remaining)" ,
                value: f.team_id
            }
        });

        state.teams.sort();
    } catch (error) {

//
//        state.teams = TEAMS.map(f => ({
//            label: f.name,
//            value: f.id
//        }))

        console.error("Can't fetch teams endpoint");
        console.error(error);
    }

    onTeamsChange();
}


const assignTeam = async (team_id) => {
    try {
        const username = state.user.username.split('_')[1];
        const route = toRoute(`/users/${username}/team`);
        const payload = {
            "body": {
                "team_id": team_id
            }
        };

        if (execute) {
            console.log(route);
            console.log(payload);
            
            try {
                await API.post("primary", route, payload);
            }
            catch {
                alert("There was an issue joining that team. Please try again.")
                window.location.reload();
            }            

            await fetchTeam(username);
            window.location.reload();
        } else {
            console.log(`Would POST to ${route}`, payload);
        }
    } catch (error) {
        console.error("Can't save team", error);
    }
}

const createTeam = async (team_name) => {
    try {
        const route = toRoute(`/teams`);
        const payload = {
            "body": {
                "team_name": team_name
            }
        };

        if (execute) {
            const team = await API.post("primary", route, payload);

            // Now assign the user
            await assignTeam(team.team_id);
            window.location.reload();
        } else {
            console.log(`Would POST to ${route}`, payload);
        }
    } catch (error) {
        console.error("Can't save team", error);
    }
}

export {
    getUser,
    fetchTeam,
    fetchTeams,
    signIn,
    subscribeUserChange,
    teamsChangeSub,
    createTeam,
    assignTeam
}