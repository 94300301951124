import { useCollection } from '@awsui/collection-hooks';
import { Table, Header, Pagination, Box, Button, TextFilter } from '@awsui/components-react';

import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES
} from "../../../resources/team_tracker";

import '../../../styles/table.scss';

const TrackerTable = ({ data, loading }) => {
  const { items, collectionProps, filterProps, paginationProps } = useCollection(data, {
    pagination: { pageSize: DEFAULT_PREFERENCES.pageSize },
    sorting: {},
    filtering: {
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No matches</b>
        </Box>
      )
    }
  });

  return (
    <Table
      {...collectionProps}
      className='teammates_table'
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={DEFAULT_PREFERENCES.visibleContent}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      header={<Header variant="h2">Your Teammates</Header>}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          filteringPlaceholder="Search teammates"
        />
      }
    />
  );
};

export default TrackerTable;