import { AttributeGreaterThanOrEqualToRule, AttributeNotMatch, NotFutureDateRule, RequiredRule, WithinDateRangeRule, AttributeLessThanRule } from "../common/ValidationRules.jsx";
import { EVENT_END_DATE, EVENT_START_DATE, MINIMUM_ACTIVITY_TIME, MAXIMUM_ACTIVITY_TIME } from "../../resources/constants.jsx";

export default {
    activity_date: [
      RequiredRule('You must provide a date for the activity.'),
      WithinDateRangeRule(EVENT_START_DATE, EVENT_END_DATE, `The selected date must be between ${EVENT_START_DATE} and ${EVENT_END_DATE}`),
      NotFutureDateRule('You can\'t submit activities in the future')
    ],
    activities: [
      RequiredRule('You must add activity items.'),
      AttributeGreaterThanOrEqualToRule("duration", MINIMUM_ACTIVITY_TIME, `Activity durations must be greater than ${MINIMUM_ACTIVITY_TIME} minute`),
      AttributeLessThanRule("duration", MAXIMUM_ACTIVITY_TIME, `Activity durations must be less than ${MAXIMUM_ACTIVITY_TIME} minutes`),
      AttributeNotMatch("activity", "", "Activity entries must have an associated label")
    ]
}