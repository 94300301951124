import React from 'react';
import { Container, Box } from '@awsui/components-react';
import TopNavigation from './common/TopNavigation';

const FourZeroFour = ({ user }) => (
  <>
    <TopNavigation user={user} />
    <Container>
      <Box textAlign="center" color="inherit">
        <b>The page you tried was not found</b>
        <Box variant="p" color="inherit">
          You may have typed the address incorrectly or you may have used an outdated link.
        </Box>
      </Box>
    </Container>
  </>
);

export default FourZeroFour;