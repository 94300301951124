import React from "react";
import { AppLayout, Button, Container, Grid, Header } from '@awsui/components-react';

import TopNavigation from "./common/TopNavigation";
import '../styles/login.scss';

const Login = ({ signIn }) => {
  return (
   <>
    <TopNavigation />
    <AppLayout
      headerSelector='#h'
      disableContentPaddings={false}
      content={<Content signIn={signIn} />}
      navigationHide={true}
      contentType="default"
      toolsHide={true}
    />
  </>
  )
}

const Content = ({ signIn }) => {
  return (
    <Grid gridDefinition={[
      { colspan: { xl: 4, l: 3, s: 2, xxs: 1 } },
      { colspan: { xl: 4, l: 6, s: 8, xxs: 10 } },
      { colspan: { xl: 4, l: 3, s: 2, xxs: 1 } }
    ]}>
    <div></div>
    <Container className="login-custom" header={<Header>Login to the Wellness Challenge</Header>}>
      <Button className="login-button" variant="primary" onClick={() => signIn()}>Sign In With Midway</Button>
    </Container>
    <div></div>
    </Grid>
  )
}

export default Login;