import { Header, SpaceBetween, Button } from '@awsui/components-react';

const TableHeader = ({ selected, counter, handleDelete }) => {
  const buttons = [
    { text: 'Delete', onClick: () => handleDelete(selected), disabled: selected.length === 0 }
  ];

  return (
    <Header
      variant="h2"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          {buttons.map((button, key) => (
            <Button
              key={key}
              disabled={button.disabled}
              variant={button.variant || 'normal'}
              onClick={button.onClick || null}
              href={button.href || ''}
            >
              {button.text}
            </Button>
          ))}
        </SpaceBetween>
      }
    >
      All Activities
    </Header>
  );
};

export default TableHeader;