import React from 'react';
import { FormField, DatePicker } from '@awsui/components-react';
import { validateField } from '../../common/ValidationRules';

export default ({ value, setValue, validationRules }) => {  
  return (
    <FormField
      label="Pick a date"
      description="Pick a date"
      stretch={true}
      errorText={validateField(validationRules, value)}
    >
      <DatePicker
        onChange={({ detail }) => setValue(detail.value)}
        value={value}
        openCalendarAriaLabel={selectedDate =>
          "Choose Date" +
          (selectedDate
            ? `, selected date is ${selectedDate}`
            : "")
        }
        nextMonthAriaLabel="Next month"
        placeholder="YYYY/MM/DD"
        previousMonthAriaLabel="Previous month"
        todayAriaLabel="Today"
      />
    </FormField>
  );
};