import React, { useState, useEffect } from 'react';
import { Container, Header, LineChart, Box, Button } from '@awsui/components-react';

import { EVENT_DATES } from '../../../resources/constants';

const ActivityLineChart = ({ loading, activities }) => {
  const [timeSeries, setTimeSeries] = useState([]);

  useEffect(
    () => {
      const _data = EVENT_DATES.map(date => {
        const activity = activities.filter(item => item.date === date);
        const duration = activity.reduce((a, b) => a + b.duration, 0)
        return { x: date, y: duration }
      });
      // if all durations are 0, there are no activities entered, so keep empty
      if (_data.reduce((sum, { y }) => sum + y, 0) === 0) setTimeSeries([]);
      else {
        const output = [{
          title: "Daily Duration",
          type: "line",
          data: _data,
          valueFormatter: (e) => e + " minutes"
        }];
        setTimeSeries(output);
      }
    },
    [activities]
  );

  return (
    <Container header={<Header variant="h2">Duration by Day</Header>}>
      <CustomLineChart series={timeSeries} loading={loading} />
    </Container>
  )
}

const CustomLineChart = ({ series, loading }) => {
  return (
    <LineChart
      series={series}
      hideFilter={true}
      hideLegend={true}
      statusType={loading ? "loading" : "finished"}
      i18nStrings={{
        filterLabel: 'Filter displayed data',
        detailPopoverDismissAriaLabel: false,
        filterPlaceholder: 'Filter data',
        filterSelectedAriaLabel: 'selected',
        legendAriaLabel: 'Legend',
        chartAriaRoleDescription: 'line chart',
        detailTotalLabel: 'Total',
        yTickFormatter: t => (Math.abs(t) < 1e3 ? t : (t / 1e3).toFixed(1) + 'k')
      }}
      ariaLabel="Multiple data series line chart"
      detailPopoverSize="large"
      errorText="Error loading data."
      height={220}
      loadingText="Loading chart"
      recoveryText="Retry"
      xScaleType="categorical"
      xTitle="Date"
      yTitle="Minutes of Activity"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
};

export default ActivityLineChart;