import React, { useEffect, useState } from "react";
import { AppLayout, SpaceBetween, Grid } from "@awsui/components-react";

import API from "@aws-amplify/api";

import "../../styles/activities.scss";

import ServiceNavigation from "../common/ServiceNavigation.jsx";
import TopNavigation from "../common/TopNavigation";

import Breadcrumbs from "./_breadcrumbs";
import PageHeader from "./_pageHeader";
import { LineChart, PieChart, Table } from "./containers/Containers";
import { SUPPORTED_ACTIVITIES } from "../../resources/constants";
import { validateActivityArray } from "../../libs/utils.js";

const Activities = ({ user }) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  const parseActivityData = (data) => {
    let output = [];
    data.forEach((item) => {
      for (let obj of item.activities) {
        const activity = SUPPORTED_ACTIVITIES.find((x) => x.value === obj.activity);
        output.push({
          date: item.activity_date,
          activity: obj.activity,
          activity_name: !!activity ? activity.label : `Other (${obj.activity.split("other_")[1]})`,
          duration: obj.duration,
        });
      }
    });
    return output;
  };

  const fetchData = async () => {
    setLoading(true);
    let username = user.username;
    if (user.username.includes("_")) username = user.username.split("_")[1];
    const data = await API.get("primary", `/activities/${username}`);
    const parsed = parseActivityData(data);
    setActivities(parsed);
    setLoading(false);
  };

  const handleDelete = async (selected) => {
    setLoading(true);
    // find distinct activity_dates in selection array
    const distinctDates = [...new Set(selected.map((item) => item.date))];

    for (let date of distinctDates) {
      const toDeleteRaw = selected.filter((item) => item.date === date);
      const toDeleteClean = toDeleteRaw.map((entry) => ({ activity: entry.activity, duration: entry.duration }));

      let body = {
        username: user.username,
        team_id: user.team_id,
        activity_date: date,
        activities: toDeleteClean,
      };

      await API.del("primary", "/activities", { body });
    }
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TopNavigation user={user} />
      <AppLayout
        headerSelector="#h"
        navigation={<ServiceNavigation />}
        breadcrumbs={<Breadcrumbs />}
        content={<Content loading={loading} activities={activities} refresh={fetchData} handleDelete={handleDelete} />}
        contentType="default"
        toolsHide={true}
      />
    </>
  );
};

const Content = ({ loading, activities, refresh, handleDelete }) => {
  return (
    <SpaceBetween size="m">
      <PageHeader refresh={refresh} />
      <Grid
        gridDefinition={[
          { colspan: { l: 12, default: 12 } },
          { colspan: { l: 6, default: 12 } },
          { colspan: { l: 6, default: 12 } },
        ]}
      >
        <LineChart loading={loading} activities={activities} />
        <PieChart loading={loading} activities={activities} />
        <Table loading={loading} activities={activities} refresh={refresh} handleDelete={handleDelete} />
      </Grid>
    </SpaceBetween>
  );
};

export default Activities;
