import { BreadcrumbGroup } from '@awsui/components-react';

// Breadcrumb content
const Breadcumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '/'
      },
      {
        text: 'All Teams Leaderboard',
        href: '/team_leaderboard'
      }
    ]}
  />
);

export default Breadcumbs;