import { useState } from 'react';
import { useCollection } from '@awsui/collection-hooks';
import { Box, Button, CollectionPreferences, Pagination, TextFilter, Table } from '@awsui/components-react';
import { useNavigate } from 'react-router-dom';

import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  DEFAULT_PREFERENCES
} from "../../../resources/activities.jsx";

import TableHeader from './_tableHeader.jsx';

const ActivityTable = ({ activities, loading, refresh, handleDelete }) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const { items, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(activities, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    filtering: {
      empty: (
        <Box textAlign="center" color="inherit">
          <b>No matches</b>
          <Box color="inherit" variant='p' margin={{ top: 'xxs', bottom: 's' }}>
            You haven't completed any actvities yet!
            Press the button to log an activity.
          </Box>
          <Button onClick={() => navigate("/submit", { replace: true })}>Log an Activity</Button>
        </Box>
      )
    }
  });

  const headerCounter = (selected, sources) => {
    return selected.length ? `(${selected.length} of ${sources.length})` : `(${sources.length})`;
  };

  const handleDeleteAndClear = selectedItems => {
    setSelected([]);
    handleDelete(selectedItems);
  }

  const filterCounter = count => `${count} ${count === 1 ? 'match' : 'matches'}`;

  return (
    <Table
      {...collectionProps}
      columnDefinitions={COLUMN_DEFINITIONS}
      stickyHeader={true}
      selectionType='multi'
      visibleColumns={preferences.visibleContent}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      header={<TableHeader refresh={refresh} selected={selected} counter={headerCounter(selected, items)} handleDelete={handleDeleteAndClear} />}
      sortingDisabled={true}
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: 'Page size',
            options: PAGE_SELECTOR_OPTIONS
          }}
          visibleContentPreference={{
            title: 'Select visible columns',
            options: CONTENT_SELECTOR_OPTIONS
          }}
          wrapLinesPreference={{
            label: 'Wrap lines',
            description: 'Check to see all the text and wrap the lines'
          }}
        />
      }
      wrapLines={preferences.wrapLines}
      selectedItems={selected}
      onSelectionChange={({ detail }) => setSelected(detail.selectedItems)}
      pagination={<Pagination {...paginationProps} />}
      filter={
        <TextFilter
          {...filterProps}
          countText={filterCounter(filteredItemsCount)}
          filteringPlaceholder="Search activities"
        />
      }
    />
  );
};

export default ActivityTable;