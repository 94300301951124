import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withRouter } from './hooks/withRouter';
import { SideNavigation } from '@awsui/components-react';

const ServiceNavigation = props => {
  const navigate = useNavigate();

  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      navigate(ev.detail.href, { replace: true });
    }
  }

  return (
    <SideNavigation
      items={items}
      activeHref={`${props.router.location.pathname}`}
      onFollow={onFollowHandler}
    />
  );
}

const items = [
  /*{ type: 'link', text: 'Log Activity', href: '/submit' }*/,
  { type: 'link', text: 'Activity Tracker', href: '/activities' },
  { type: 'link', text: 'Team Tracker', href: '/team_tracker' },
  { type: 'link', text: 'Team Leaderboard', href: '/team_leaderboard' },
  { type: 'divider' }
];

export default withRouter(ServiceNavigation);
