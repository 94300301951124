let config =  {
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_ieMb0PUON",
    IDENTITY_POOL_ID: "us-west-2:c11a3f76-5bb1-4503-8112-9e1a2a4d1906",
    APP_CLIENT_ID: "3ke333a3t3jmo2q9re88l4dsu7",
    OAUTH: {
      domain: "wellbeing-app-prod-c81522f0.auth.us-west-2.amazoncognito.com",
      scope: [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      redirectSignIn: "https://wellness.wwps.aws.dev", 
      redirectSignOut: "https://wellness.wwps.aws.dev",
      responseType: "code"
    }
  },
  api: {
    primary: {
      URL: "https://9qj4f11um8.execute-api.us-west-2.amazonaws.com/prod/",
      REGION: "us-west-2"
    }
  }
}

export default config;