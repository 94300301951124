import React, { useState, useEffect } from 'react';
import * as user_data from "./data/user";

import {
    AppLayout,
    Button,
    Icon,
    Container,
    Header,
    Box,
    Grid,
    SpaceBetween,
    Link,
    Select,
    FormField,
    Input
} from '@awsui/components-react';


import '../styles/home.scss';


const TeamHandler = () => {
    const defaultSelectedOption = { label: "Please choose a team", value: "1", iconName: "settings", description: "This is a description" };
    const [selectedOption, setSelectedOption] = React.useState(defaultSelectedOption);
    const [teamOptions, setTeamOptions] = React.useState([]);
    const [teamName, setTeamName] = React.useState('');

    user_data.teamsChangeSub(teams => {
        if (teamOptions.length > 0) {
            return
        }

        setTeamOptions(teams);
    })

    const onSelectedChange = (detail) => {
        setSelectedOption(detail.selectedOption);
        setTeamName(detail.selectedOption.value);
    }

    const onTeamNameSetChange = (value) => {
        setTeamName(value);
    }

    const resetState = () => {
        setTeamName('');
        setSelectedOption(defaultSelectedOption);
    }


    return {
        selectedOption,
        teamOptions,
        teamName,
        onTeamNameSetChange,
        onSelectedChange,
        resetState
    }

}

const Teams = (props) => {
    if (props.showAddTeam) {
        return (
            <div>
                <Select
                    selectedOption={props.handler.selectedOption}
                    onChange={({ detail }) => props.handler.onSelectedChange(detail)}
                    // triggerVariant='option'
                    options={props.handler.teamOptions} filteringType="auto" />
            </div>
        );
    } else if (props.showCreateTeam) {
        return (
            <div>
                <div>
                    <FormField
                        constraintText="Team names will be visible to everyone"
                        label="Team Name">
                        <Input placeholder='Example - Team AWEsome' value={props.handler.teamName} onChange={({ detail }) => props.handler.onTeamNameSetChange(detail.value)} />
                    </FormField>
                </div>
            </div>
        )
    }
}

const AssignTeam = () => {
    const [saving, setSaving] = React.useState(false);
    const [showAddTeam, setShowAddTeam] = React.useState(false);
    const [showCreateTeam, setCreateTeam] = React.useState(false);
    const handler = TeamHandler();

    const allowTeamRegistration = false;

    const resetCallback = () => {
        setShowAddTeam(false);
        setCreateTeam(false);
        setSaving(false);
        handler.resetState();
    }


    const saveTeam = () => {
        setSaving(true);

        const p = [];

        if (showCreateTeam) {
            p.push(user_data.createTeam(handler.teamName));
        } else {
            p.push(user_data.assignTeam(handler.selectedOption.value));
        }

        let promiseExecution = async () => {
            for (let promise of p) {
                try {
                    await promise;
                } catch (error) {
                    console.log(error.message);
                }
            }
        };

        promiseExecution().then( p => {
            setTimeout(() => {
                resetCallback();
            }, 1000);
        });
    };

    useEffect(
        () => {
            user_data.fetchTeams().then(f => { });
        }
    );


    return (
        <Container>
            <SpaceBetween size="xl">
                <Box variant="h2" padding="n">
                    { allowTeamRegistration ? 'Next step, team up!' : 'Team Registration is now Closed!' }
                </Box>
                {
                    allowTeamRegistration ? 
                    !showAddTeam && !showCreateTeam ?
                        <SpaceBetween direction="horizontal">
                            <Button variant="primary" onClick={() => setShowAddTeam(true)} >Choose Team</Button>
                            <Button variant='link' onClick={() => setCreateTeam(true)} >New team</Button>
                        </SpaceBetween>
                        :

                        <SpaceBetween direction="vertical" size="xs">
                            <Teams showAddTeam={showAddTeam} showCreateTeam={showCreateTeam} handler={handler} />
                            <div className="actions">
                                <Button variant='primary' onClick={saveTeam} loading={saving}>Save</Button>
                                <Button variant='link' onClick={resetCallback} disabled={saving} >Cancel</Button>
                            </div>
                        </SpaceBetween>
                    : ''
                }
            </SpaceBetween>



        </Container>
    )
}

export default AssignTeam;