import React, { useEffect, useState } from "react";
import { AppLayout, SpaceBetween, Grid } from '@awsui/components-react';

import API from "@aws-amplify/api";

import ServiceNavigation from "../common/ServiceNavigation.jsx";
import TopNavigation from "../common/TopNavigation";

import Breadcrumbs from './_breadcrumbs';
import PageHeader from './_pageHeader';
import { Table, PieChart } from './containers/Containers'
// import TrackerImage from '../common/TrackerImage';
import TrackerImageWorld from '../common/TrackerImageWorld';

const TeamTracker = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState([]);
  const [activities, setActivities] = useState([]);
  const [teammates, setTeammates] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    // fetch the users team object
    const teams  = await API.get("primary", `/teams`);
    const team = teams.find(t => t.team_id === user.team_id);
    const teamData = await API.get("primary", `/teams/${user.team_id}`);

    // fetch all user data for the given team
    const userData = await API.patch("primary", `/teams/${user.team_id}`);

    const marker = {
      team_id: teamData.team_id,
      name: teamData.team_name,
      progress: teamData.totalJourneyProgress,
      team_icon: teamData.team_icon
    }

    setTeammates(userData["users"])
    setActivities(userData["activities"])

    setProgress([marker]);
    setLoading(false);
  }

  useEffect(
    () => {
      fetchData()
    }, []);

  return (
    <>
      <TopNavigation user={user} />
      <AppLayout
        headerSelector="#h"
        navigation={<ServiceNavigation />}
        breadcrumbs={<Breadcrumbs />}
        content={<Content
          loading={loading}
          teammates={teammates}
          activities={activities}
          progress={progress}
          refresh={fetchData}
        />}
        contentType="default"
        toolsHide={true}
      />
    </>
  );
};

const Content = ({ loading, teammates, activities, progress, refresh }) => {
  return (
    <SpaceBetween size="m">
      <PageHeader refresh={refresh} />
      <Grid
        gridDefinition={[
          { colspan: { s: 5, l: 6, default: 12 }},
          { colspan: { s: 7, l: 6, default: 12 }},
          { colspan: { l: 12, default: 12 }}
        ]}
      >
        <Table data={teammates} loading={loading} />
        <PieChart series={activities} loading={loading} />
        <TrackerImageWorld points={progress} />
      </Grid>
      
    </SpaceBetween>
  )
}

export default TeamTracker;