import React from 'react';
import {
  AppLayout,
  Button,
  Icon,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link,
  Select,
  FormField,
  Input
} from '@awsui/components-react';

import ServiceNavigation from './common/ServiceNavigation.jsx';
import TopNavigation from './common/TopNavigation.jsx';
import AssignTeam from './Home_Team.jsx';

import '../styles/home.scss';

const Home = ({ user }) => {
  const eventActive = false;

  return (
    <>
      <TopNavigation user={user} />
      <AppLayout
        headerSelector='#h'
        disableContentPaddings={true}
        navigation={<ServiceNavigation />}
        content={<Content user={user} eventActive={eventActive} />}
        contentType="default"
        toolsHide={true}
        navigationHide={user.team_id && eventActive ? false : true}
      />
    </>
  );
};

const Content = (props) => {
  const hasTeam = props.user.team_id;

  return (
    <Box className='main-layout' margin={{ bottom: 'l' }}>
      <div className="custom-home__header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { colspan: { xxs: 10, l: 8 }, offset: { xxs: 1, l: 2 } },
              { colspan: { xxs: 10, s: 7, l: 5, xl: 6 }, offset: { xxs: 1, s: 1, l: 2 } },
              { colspan: { xxs: 10, s: 3, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
            ]}
          >
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category">Worldwide Public Sector</span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                Wellness Challenge
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                World Wide Public Sector
              </Box>
            </div>
            <div className="custom-home__header-cta">
              { hasTeam ? 
                <Container>
                  <SpaceBetween size="xl">
                    <Box variant="h2" padding="n">
                      Did an activity today?
                    </Box>
                    <Button href="/submit" variant="primary" disabled={!props.eventActive}>
                      Log Your Activity
                    </Button>
                    { 
                        !props.eventActive ?
                          <div><em>The Wellness Event has concluded. Thank you for participating!</em></div> :
                          ''
                      }
                  </SpaceBetween>
                </Container>
              :
                <AssignTeam />
              }
            </div>
          </Grid>
        </Box>
      </div>

      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { colspan: { xxs: 10, s: 7, l: 5, xl: 6 }, offset: { xxs: 1, s: 1, l: 2 } },
            { colspan: { xxs: 10, s: 3, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
          ]}
        >
          <SpaceBetween className="main-content" size="xxl">
            <div>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                Overview
              </Box>
              <Container>             
                Welcome to the <strong>&nbsp;September 2023 AWS Wellness Challenge!</strong>
                <br />
                You are encouraged to build your own mental and physical wellbeing by participating in this team-based WWPS Wellness Challenge.
                Undertake 30 minutes of intentional activity every day across the month of the challenge.
              </Container>
            </div>
            <div>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                FAQs
              </Box>
              <Container>
                <ul className="custom-list-separator">
                <li><strong>Q: Who can take part in the Wellness Challenge?</strong></li>
                <li>A: Anyone in AWS can opt-in to this challenge and build healthy habits and create meaningful connections with others along the way.</li>
                <li><strong>Q: How does this work?</strong></li>
                <li>A: Interested individuals will initially register for the Wellness Challenge on Gather.After Aug 23, an E-mail will be sent to all registered participants so that can create their own team or joining an existing team. Each team is min 5 members and max 10 members. Individuals within the teams will embark on 30 minutes of intentional activity each day and log up to 30 minutes maximum on the Wellness App tracker. Teams with the most activity minutes at the end of the month will be rewarded with public recognition. The challenge is based on the honesty principle. Participants can complete and log more than 30 minutes per day, however, 30 minutes is the maximum daily amount of time that will count towards the team goal.</li>
                <li><strong>Q: Is there a prize for the winning team/winning individual?</strong></li>
                <li>A: Every participant who completes the challenge will receive a phone tool icon, but best of all we hope your well-being will improve and your AWS network increase.</li>
                <li><strong>Q: If I do more than 30 mins of activities, does that help my team progress more quickly?</strong></li>
                <li>A: No. We will count a maximum of 30 mins activity per person per day. By all means, feel free to do more intentional activity, but any minutes added above 30 per day will not count towards the team effort.</li>
                <li><strong>Q: What if I complete 30 minutes of activity per day, but forget to log the activity minutes? </strong></li>
                <li>A. You can go back and log your activity minutes after the day passes. So, if you are unable to log the activity on one day, you should go back as soon as you’re are able to log the time as each Challenge team moves collectively as each participant tracks their time.</li>
                <li><strong>Q: If I complete 60 mins one day and can’t complete any activities the next day, can I allocate the 60 mins across two days?</strong></li>
                <li>A: No. This challenge to encourage daily focused time on wellbeing, whatever time you can manage on any particular day is what we ask that you enter.</li>
                <li><strong>Q: Can I be on the same team as a friend?</strong></li>
                <li>A: Yes – you can join an existing team or create a new team with friends. </li>
                <li><strong>Q: How do I communicate with my team?</strong></li>
                <li>A: A Wellbeing Challenge Slack Group has been opened for all challenge participants. Your individual challenge team is also encouraged to create your own Slack group to communicate to and motivate each other.</li>
                <li><strong>Q: How do I track my team's progress?</strong></li>
                <li>A: You will be able to see your team's progress within the Wellness Challenge Application. The map is updated real time for groups and individual progress.</li>
                <li><strong>Q: What if one of our team drops out or I am unable to participate?</strong></li>
                <li>A: Please email wwps-e3program@amazon.com if you are unable to take part in the Wellness Challenge due to any reason. We will assess the potential impact to overall team performance to determine best next steps.</li>
                <li><strong>Q: If we have fewer people in our team, are we disadvantaged or do you take account of number of participants per team?</strong></li>
                <li>A: We take account of the number of people in the team so that any team that has fewer people is not disadvantaged.</li>
                <li><strong>Q: I have another question, who do I reach out to?</strong></li>
                <li>A: Please direct all questions to wwps-e3program@amazon.com email box.</li>
                <li><strong>Q: Do I have to track my times daily, or can I do it on a weekly basis, updating each day for the week but only on Friday?</strong></li>
                <li>A: We encourage you to enter your activity as soon as possible as it will impact the leader boards.</li>

                </ul>
              </Container>
            </div>
          </SpaceBetween>
          <div className="custom-home__sidebar">
            <SpaceBetween size="m">
              <Container
                header={
                  <Header variant="h2">
                    More resources{' '}
                    <span role="img" aria-label="Icon external Link">
                      <Icon name="external" size="inherit" />
                    </span>
                  </Header>
                }
              >
                <ul aria-label="Additional resource links" className="custom-list-separator">
                  <li>
                    <Link href="https://w.amazon.com/bin/view/MHW" target='_blank'>Amazon Mental Health and Well-being Affinity Group</Link>
                  </li>
                  <li>
                    <Link href="https://news.a2z.com/contents/33443467" target='_blank'>Twill Therapeutics</Link>
                  </li>
                  <li>
                    <Link href="https://w.amazon.com/bin/view/MHW/selfcare" target='_blank'>Mental Health and Well-being Self-care Training</Link>
                  </li>
                  <li>
                    <Link href="https://inside.hr.amazon.dev/us/en/employment/time-off-compensation-benefits/benefits-global/employee-assistance-program.html" target='_blank'>Employee Assistance Program (EAP) 24/7</Link>
                  </li>
                  <li>
                    <Link href="https://www.resourcesforliving.com/login" target='_blank'>Resources for Living</Link>
                  </li>
                  <li>
                    <Link href="https://knet.csod.com/ui/lms-learning-details/app/course/f21e51b2-b95f-4b19-83ab-0b32793eaa1e" target='_blank'>Mental Health Awareness at Amazon</Link>
                  </li>
                  <li>
                    <Link href="https://inside.amazon.com/en/Pages/New-discounts-for-employees-available-on-Amazon-Extras.aspx" target='_blank'>Amazon Extras Program (employee discounts)</Link>
                  </li>
                </ul>
              </Container>
              <Container
                header={
                  <Header variant="h2">
                    Instructions
                  </Header>
                }
              >
                <ul className="custom-list-separator">
                  <li>Log your activities using the 'Log an Activity' button above or 'Log Activity' menu item on the left</li>
                  <li>Each activity logged will add to your total activity duration and contribute to your teams progress</li>
                  <li>A maximum of 30 minutes of user activity per day adds to a teams progress</li>
                  <li>Use the Activity Tracker to track your progress and see where your team is overall!</li>
                </ul>
              </Container>
            </SpaceBetween>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;