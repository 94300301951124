import { BreadcrumbGroup } from '@awsui/components-react';

// Breadcrumb content
const Breadcumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Home',
        href: '/'
      },
      {
        text: 'Activity Tracker',
        href: '/activities'
      }
    ]}
  />
);

export default Breadcumbs;