import React, { useState } from 'react';
import { HelpPanel, Icon, Link } from '@awsui/components-react';

const getFormattedContent = tools => (
  <HelpPanel
    header={<h2>{tools.title}</h2>}
    footer={
      !!tools.links ? (
        <div>
          <h3>
            Learn more <Icon name="external" />
          </h3>
          <ul>
            {tools.links.map((link, i) => (
              <li key={i}>
                <Link href={link.href} text={link.text} />
              </li>
            ))}
          </ul>
        </div>
      ) : null
    }
  >
    {tools.content}
  </HelpPanel>
);

const useTools = toolContent => {
  const [content, setContent] = useState(getFormattedContent(toolContent.default));
  const [isOpen, setIsOpen] = useState(false);

  const setFormattedContent = tools => {
    setContent(getFormattedContent(tools));
  };

  const openTools = name => {
    if (name) {
      setFormattedContent(toolContent[name]);
    }
    setIsOpen(true);
  };
  const closeTools = () => {
    setIsOpen(false);
  };

  const onChange = evt => setIsOpen(evt.detail.open);

  return {
    content,
    isOpen,
    openTools,
    closeTools,
    setFormattedContent,
    onChange
  };
};

export default useTools;
