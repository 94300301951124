import { useCollection } from '@awsui/collection-hooks';
import { Table, Header } from '@awsui/components-react';

import {
  COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES
} from "../../resources/team_leaderboard";

import '../../styles/table.scss';

const TeamTable = ({ teamData, loading }) => {
  const { items } = useCollection(teamData, {});

  return (
    <Table
      className='team_table'
      columnDefinitions={COLUMN_DEFINITIONS}
      visibleColumns={DEFAULT_PREFERENCES.visibleContent}
      items={items}
      loading={loading}
      loadingText="Loading resources"
      header={<Header variant="h2">Team Progress</Header>}
      sortingDisabled={true}
    />
  );
};

export default TeamTable;