import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { Amplify } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';

import App from './components/App';
import config from './config';

import '@awsui/global-styles/index.css';
import '@aws-amplify/ui-react/styles.css';
import './styles/index.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: config.cognito.OAUTH
  },
  API: {
    endpoints: [
      {
        name: 'primary',
        endpoint: config.api.primary.URL,
        region: config.api.primary.REGION,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        })
      }
    ]
  },

});


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);