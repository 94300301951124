import React, { useEffect, useState } from "react";
import { AppLayout, SpaceBetween } from '@awsui/components-react';
import API from "@aws-amplify/api";

import '../../styles/team_leaderboard.scss';

import ServiceNavigation from "../common/ServiceNavigation.jsx";
import TopNavigation from "../common/TopNavigation";

import Breadcrumbs from './_breadcrumbs';
import PageHeader from "./_pageHeader.jsx";
import Table from './_table';
import TrackerImage from '../common/TrackerImageWorld';

const TeamLeaderboard = ({ user }) => {
  const [progress, setProgress] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nTeams, setNTeams] = useState(25);

  const fetchData = async () => {
    setLoading(true);
    const data = await API.get("primary", `/leaderboard/teams`);
    // sort data as a failsafe
    let sorted = data.sort((a, b) => a.totalJourneyProgress < b.totalJourneyProgress ? 1 : -1);

    let _markers = sorted.map(team => ({
      team_id: team.team_id,
      name: team.team_name,
      progress: team.totalJourneyProgress,
      team_icon: team.team_icon
    }));
    setProgress(_markers);
    
    setMarkers(_markers);
    setTeamData(sorted);
    setLoading(false);
  }

  useEffect(
    () => {
      fetchData()
    },
    []
  );

  useEffect(() => {
    let _markers = [...progress];
    _markers.splice(nTeams);
    setMarkers(_markers);
  }, [nTeams]);

  return (
    <>
      <TopNavigation user={user} />
      <AppLayout
        headerSelector="#h"
        navigation={<ServiceNavigation />}
        breadcrumbs={<Breadcrumbs />}
        content={<Content loading={loading} teamData={teamData} markers={markers} refresh={fetchData} nTeams={nTeams} setNTeams={setNTeams} />}
        contentType="default"
        toolsHide={true}
      />
    </>
  );
};

const Content = ({ loading, teamData, markers, refresh, nTeams, setNTeams }) => {
  return (
    <SpaceBetween size="m">
      <PageHeader refresh={refresh} onChange={setNTeams} />
      <TrackerImage loading={loading} points={markers} />
      <Table loading={loading} teamData={teamData} refresh={refresh} />
    </SpaceBetween>
  )
}

export default TeamLeaderboard;