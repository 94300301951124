import React from 'react';

export const TOOLS_CONTENT = {
  default: {
    title: 'DEFAULT TOOLS CONTENT',
    content: <div />,
    links: []
  }
}

export const COLUMN_DEFINITIONS = [
  {
    id: 'date',
    cell: item => item.date,
    header: 'Date',
    sortingField: 'date'
  },
  {
    id: 'activity',
    cell: item => item.activity_name,
    header: 'Activity',
    sortingField: 'activity'
  },
  {
    id: 'duration',
    cell: item => `${item.duration} minutes`,
    header: 'Duration',
    sortingField: 'duration'
  }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main data source properties',
    options: [
      { id: 'date', label: 'Date', editable: true },
      { id: 'activity', label: 'Activity', editable: true },
      { id: 'duration', label: 'Duration', editable: true },
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Items' },
  { value: 30, label: '30 Items' },
  { value: 50, label: '50 Items' }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [ 'date', 'activity', 'duration' ],
  wraplines: false
};
