import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppLayout, Button, Container, Box, Link } from '@awsui/components-react';
import * as user_data from "./data/user";

import '../styles/App.css';

import Home from './Home';
import Submit from './submit/Submit';
import Activities from './activities/Activities';
import TeamTracker from './team_tracker/TeamTracker';
import FourZeroFour from './FourZeroFour';
import TeamLeaderboard from './team_leaderboard/TeamLeaderboard';
import Login from './Login';
import Loading from './common/Loading';

const App = () => {
  const [user, setUser] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [signUpEnabled, setSignUpEnabled] = useState(false);

  useEffect(
    () => {
      try {
        user_data.getUser().then(userData => {

          if (!!userData) {
            //  setCheckingAuth(true);
            setShowSignUp(false);

            user_data.fetchTeam(userData.username).then(response => {

            }).catch(e => {

              setShowSignUp(true);
            });

          } else {
            setCheckingAuth(false);
          }
        }).catch(e => {
          // Set please sign up
          setShowSignUp(true);
          setCheckingAuth(false);
        });
      } catch {
        setShowSignUp(true);
      }
    },
    []
  );

  useEffect(
    () => {
      if (!!user) setCheckingAuth(false);
    },
    [user]
  );

  user_data.subscribeUserChange((user) => {
    setUser(user);
  })

  return showSignUp ? (<>
    <AppLayout
      headerSelector='#h'
      disableContentPaddings={false}
      content={
        <Container>
          {
            signUpEnabled ?   
              <Box textAlign="center" color="inherit">
                <b>It looks like you're not signed up for the current AWS Wellness Challenge</b>
                <Box variant="p" color="inherit" margin={'l'}>
                  To participate in the wellness challenge we encourage you to sign up and team up with your fellow Amazonians. Once you sign up we will add you to the challnege within the next 24 hours.
                </Box>
                <Box margin={'l'}>
                  <Link href='mailto:wwps-e3program@amazon.com?Subject=[Signup Request] AWS Wellness Challenge - September 2023&body=Please add me to the AWS Wellness Challenge list.'>
                    <Button variant='primary'>Request Access</Button>
                  </Link>
                  <Box variant="p" color="inherit" margin={'l'}>
                    If request access does not work, please email <strong>wwps-e3program@amazon.com</strong>
                  </Box>
                </Box>
              </Box>
            :
            <Box textAlign='center' color='inherit'>
              <b>Sorry, signups for the September Wellness Challenge are now closed.</b>
            </Box>
          }
        </Container>

      }
      navigationHide={true}
      contentType="default"
      toolsHide={true}
    />
  </>) : checkingAuth ? (
    <Loading />
  ) : (
    !user ?
      (
        <Login signIn={user_data.signIn()} />
      )
      : (
        <>
          <Routes>
            <Route path="/" element={<Home user={user} />} />
            <Route path="/submit" element={<Submit user={user} />} />
            <Route path="/activities" element={<Activities user={user} />} />
            <Route path="/team_tracker" element={<TeamTracker user={user} />} />
            <Route path="/team_leaderboard" element={<TeamLeaderboard user={user} />} />

            <Route path="*" element={<FourZeroFour user={user} />} />
          </Routes>
        </>
      )
  );
};

export default App;
