import React, { useEffect, useState, useRef } from "react";
import { Container, Header } from "@awsui/components-react";


import "leaflet/dist/leaflet.css";
import "../../styles/tracker_map_world.scss";

import L from "leaflet";
import { divIcon } from "leaflet";
import { Lethargy } from "lethargy";

import challengePathPoints from "../../resources/challenge_path_points.json";
import challengePathPointsWorld from "../../resources/challenge_world_path_points.json";
import { PLANETS } from "../../resources/constants";
import { LOCATIONS } from "../../resources/constants";


const lethargy = new Lethargy(7, 50, 0.05);
const isInertialScroll = (e) => lethargy.check(e) === false;

L.Map.ScrollWheelZoom.prototype._onWheelScroll = function (e) {
  L.DomEvent.stop(e);
  if (isInertialScroll(e)) return;

  this._delta += L.DomEvent.getWheelDelta(e);
  this._lastMousePos = this._map.mouseEventToContainerPoint(e);
  this._performZoom();
};

const MapChart = ({ loading, points }) => {
  const [markers, setMarkers] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    if (markers.length !== points.length) setMarkers(points);
  }, [points, markers.length]);

  useEffect(() => {
    const container = L.DomUtil.get(mapRef.current);
    // if (container != null) container._leaflet_id = null;
    if (!container) return;

    let map = L.map('map', {
      center: [-95, 125],
      zoom: 3.5,
      minZoom: 2.5,
      maxZoom: 8,
      zoomSnap: .5,
      crs: L.CRS.Simple,
      // renderer: L.svg({ padding: 100 })
    });

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap'
    }).addTo(map);

    // const fg_planets = addPlanetMarkersToMap(map);
    // const fg_route = addRouteToMap(map);
    const fg_locations = addLocationMarkersToMap(map);
    const fg_route = addPathToMap(map);
    const fg_markers = addWorldMarkersToMap(map);
    // const fg_pathPoints = addPointsToMap(map);
    if (!!fg_locations) {
      map.panTo(fg_locations.getBounds().getCenter())
      map.fitBounds(fg_locations.getBounds().pad(1));
    }

    let customMarker;
    map.on('click', (pnt) => {
      if (!!customMarker) customMarker.remove()
      // const icon = L.icon({
      //   iconUrl: 'images/teams/aws.png',
      //   iconSize: [ 30, 30 ]
      // })
      console.log(pnt);
      // customMarker = new L.marker(pnt.latlng, { icon: icon });
      // customMarker.addTo(map);
    });

    return () => {
      map.remove();
    };
  }, [markers]);

  // const addPlanetMarkersToMap = map => {
  //   const planetMarkerGroup = new L.FeatureGroup();

  //   PLANETS.forEach(planet => {
  //     const swPoint = [planet.pos[0] - (planet.size[0] / 2), planet.pos[1] + (planet.size[1] / 2)];
  //     const nePoint = [planet.pos[0] + (planet.size[0] / 2), planet.pos[1] - (planet.size[1] / 2)];

  //     let sw = map.unproject(swPoint, map.getMaxZoom() - 1);
  //     let ne = map.unproject(nePoint, map.getMaxZoom() - 1);
  //     let bounds = new L.LatLngBounds(sw, ne);
  //     L.imageOverlay(planet.image, bounds).addTo(planetMarkerGroup);

  //     const centre = map.unproject(planet.pos, map.getMaxZoom() - 1)
  //     const marker = new L.circle(centre, { radius: 0, color: 'white' });
  //     marker.bindTooltip(planet.name,
  //       {
  //         permanent: true,
  //         direction: 'center',
  //         offset: [0, 40]
  //       }
  //     );
  //     marker.addTo(planetMarkerGroup);
  //   });

  //   planetMarkerGroup.addTo(map);
  //   return planetMarkerGroup;
  // }

  const addLocationMarkersToMap = map => {
    const locationMarkerGroup = new L.FeatureGroup();

    LOCATIONS.forEach(location => {
    
      // let sw = L.latLng(location.pos[0], location.pos[1]);
      // let ne = L.latLng(location.pos[0]+location.size[0], location.pos[1]-location.size[1]);

      // let bounds = new L.LatLngBounds(sw, ne);
      // L.imageOverlay(location.image, bounds).addTo(locationMarkerGroup);

      // const centre = map.unproject(location.pos, map.getMaxZoom() - 1)
      // const marker = new L.circle(centre, { radius: 0, color: 'white' });
      const fontAwesomeIcon = L.divIcon({
          html: `<ion-icon name="${location.iconName}"></ion-icon>`,
          iconSize: location.iconSize,
          className: `ion-flag-large ion-flag-${location.iconColor}`
      });
      const marker = L.marker(location.pos, { icon: fontAwesomeIcon}).addTo(map);
      marker.bindTooltip(location.name,
        {
          permanent: false,
          direction: 'bottom',
          offset: location.toolTipOffset,
        }
      );
      marker.addTo(locationMarkerGroup);
    });

    locationMarkerGroup.addTo(map);
    return locationMarkerGroup;
  }

  const addPathToMap = map => {
    const routeGroup = new L.FeatureGroup().addTo(map);
    const routePoints = LOCATIONS.map(location => location.pos);
    const route = L.polyline(routePoints, { color: 'black', dashArray: '5', weight: 2, opacity: 0.5, smoothFactor: 1 });
    route.addTo(routeGroup);
    return routeGroup;
  }

  // const addRouteToMap = map => {
  //   const routeGroup = new L.FeatureGroup().addTo(map);
  //   const pathPoints = challengePathPoints.map(point => map.unproject(point, map.getMaxZoom() - 1));
  //   const route = L.polyline(pathPoints, { color: 'white', dashArray: '5', weight: 2, opacity: 0.5 })
  //   route.addTo(routeGroup);
  //   return routeGroup;
  // }

  const addTeamsToMap = map => {
    if (markers.length === 0) return;
    
    const markerGroup = new L.FeatureGroup().addTo(map);
    const pathPoints = LOCATIONS.map(location => location.pos);

    markers.forEach(point => {
      const closestPoint = Math.ceil((pathPoints.length - 1) * point.progress)
      const icon = L.icon({
        className: 'custom-marker',
        iconUrl: point.team_icon,
        iconSize: [ 25, 25 ]
      })
      const marker = new L.marker(pathPoints[closestPoint], { icon: icon });
      marker.bindPopup(point.name);
      marker.setZIndexOffset(closestPoint * 10); // large number for markers further along the path
      marker.addTo(markerGroup);
    });
    return markerGroup;
  }

  // const addMarkersToMap = map => {
  //   if (markers.length === 0) return;
    
  //   const markerGroup = new L.FeatureGroup().addTo(map);
  //   const pathPoints = challengePathPoints.map(point => map.unproject(point, map.getMaxZoom() - 1));

  //   markers.forEach(point => {
  //     const closestPoint = Math.ceil((pathPoints.length - 1) * point.progress)
  //     const icon = L.icon({
  //       className: 'custom-marker',
  //       iconUrl: point.team_icon,
  //       iconSize: [ 25, 25 ]
  //     })
  //     const marker = new L.marker(pathPoints[closestPoint], { icon: icon });
  //     marker.bindPopup(point.name);
  //     marker.setZIndexOffset(closestPoint * 10); // large number for markers further along the path
  //     marker.addTo(markerGroup);
  //   });
  //   return markerGroup;
  // }

  const addWorldMarkersToMap = map => {
    if (markers.length === 0) return;
    
    const markerGroup = new L.FeatureGroup().addTo(map);
    const pathPoints = challengePathPointsWorld; //.map(point => map.unproject(point, map.getMaxZoom() - 1));
    markers.forEach(point => {
      var team_icon = point.team_icon ? point.team_icon : 'images/teams/aws.png';
      let closestPoint = Math.ceil((pathPoints.length - 1) * point.progress)
      if (closestPoint > (pathPoints.length - 1)) {
        closestPoint = pathPoints.length - 1;
      }
      const icon = L.icon({
        className: point.progress > 0.01 ? 'custom-marker-shadow' : 'custom-marker',
        iconUrl: team_icon,
        iconSize: [ 25, 25 ]
      })
      const marker = new L.marker(pathPoints[closestPoint], { icon: icon });
      marker.bindPopup(point.name);
      marker.setZIndexOffset(closestPoint * 10); // large number for markers further along the path
      marker.addTo(markerGroup);
    });
    return markerGroup;
  }

  const addPointsToMap = map => {
    const locationMarkerGroup = new L.FeatureGroup();

    challengePathPointsWorld.forEach( point => {
      const fontAwesomeIcon = L.divIcon({
        html: `<ion-icon name="location"></ion-icon>`,
        iconSize: [25, 55],
        className: `ion-flag-large ion-flag-blue`
      });
      const marker = L.marker(point, { icon: fontAwesomeIcon}).addTo(map);
      
      marker.addTo(locationMarkerGroup);
    });

    locationMarkerGroup.addTo(map);
    return locationMarkerGroup;
  }

  return (
    <Container loading={loading} header={<Header variant="h2">Progress Map</Header>}>
      <div className="map-container">
        <div id='map' ref={mapRef}></div>
      </div>
    </Container>
  );
};

export default MapChart;
