import { SUPPORTED_ACTIVITIES } from "../resources/constants";

export const validateActivityArray = activityArray => {
  const clean = activityArray.map(entry => {
    // check if entered activity is in the standard selection
    const match = SUPPORTED_ACTIVITIES.find(item => item.label === entry.activity);
    const activity = !!match ? match.value : `other_${entry.activity.toLowerCase()}`
    return { activity: activity, duration: parseInt(entry.duration) }
  });
  return clean;
}

export const fetchJSONFileAsync = async (path) => {
  const response = await fetch(path);
  return await response.json();
};

export const fetchJSONFileAsyncPaginated = async (path, page = 1, previousResponse = []) => {
  const data = await fetchJSONFileAsync(`${path}&page=${page}`);
  const response = [...previousResponse, ...data];
  if (data.length !== 0) {
    page++;
    return fetchJSONFileAsyncPaginated(path, page, response);
  }
  return response;
};

export const formatDate = (date) => {
  const local_date = date.toLocaleDateString(undefined, {
    day: "2-digit",
    year: "numeric",
    month: "2-digit",
  });
  return local_date.split('/').join('-')
};

export const getDaysInMonth = (year, month) => {
  const num_days = new Date(year, month, 0).getDate();
  const days = [];

  for (let i=0; i<num_days; i++) {
    const day = String(i+1).padStart(2, '0');
    days.push(`${year}-${month}-${day}`);
  }
  return days;
}

export const convertTime = (_seconds) => {
  // _seconds = parseInt(_seconds, 10);
  const hours = Math.floor(_seconds / 3600);
  const minutes = Math.floor((_seconds - hours * 3600) / 60);
  const seconds = _seconds - hours * 3600 - minutes * 60;
  if (!!hours) {
    return `${hours.toLocaleString("en")} hours`;
  }
  if (!!minutes) {
    return `${minutes.toLocaleString("en")} minutes`;
  }
  return `${seconds.toLocaleString("en")} second(s)`;
};

// export const buildTeamIcon = logo => new L.Icon({
//   iconUrl: logo,
//   iconSize: [32, 32],
//   iconAnchor: [16, 16],
//   popupAnchor: [0, -16],
//   className: "custom-marker"
// });
