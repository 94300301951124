import React, { useState } from "react";
import { Flashbar, AppLayout } from '@awsui/components-react';
import API from '@aws-amplify/api';
import { useNavigate } from 'react-router-dom';

import useTools from '../common/hooks/useTools';
import ServiceNavigation from "../common/ServiceNavigation.jsx";

import Breadcrumbs from './_breadcrumbs';
import Form from './_form';

import { TOOLS_CONTENT } from "../../resources/submit.jsx";
import TopNavigation from "../common/TopNavigation";
import { validateActivityArray } from "../../libs/utils";

const Submit = ({ user }) => {
  const navigate = useNavigate();
  const tools = { ...useTools(TOOLS_CONTENT) };
  const [navOpen, setNavOpen] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [alert, setAlert] = useState([]);

  const handleSubmit = async data => {
    setSubmitted(true);
    data.username = user.username;
    data.team_id = user.team_id

    const activities_clean = validateActivityArray(data.activities);
    console.log({ ...data, activities: activities_clean });

    try {
      await API.post("primary", "/activities", { body: { ...data, activities: activities_clean } });
      setAlert([{ type: 'info', header: 'Activities successfully logged', dismissible: true, onDismiss: () => setAlert([]) }]);
      navigate("/activities");
    }
    catch (e) {
      console.error(e);
      setAlert([{ type: 'error', header: 'Error submitting activity', dismissible: true, onDismiss: () => setAlert([]) }])
    }
    setSubmitted(false);
  };

  return (
    <>
      <TopNavigation user={user} />
      <AppLayout
        stickyNotifications
        notifications={<Flashbar items={alert} />}
        headerSelector="#h"
        navigation={<ServiceNavigation />}
        navigationOpen={navOpen}
        onNavigationChange={() => setNavOpen(!navOpen)}
        breadcrumbs={<Breadcrumbs />}
        content={<Form tools={tools} handleSubmit={handleSubmit} submitted={submitted} />}
        contentType="form"
        toolsHide={true}
      />
    </>
  );
};

export default Submit;