import React, { useEffect, useCallback, useState, useMemo } from "react";
import { FormField, AttributeEditor, Autosuggest } from "@awsui/components-react";
import { DEFAULT_DURATIONS, SUPPORTED_ACTIVITIES } from "../../../resources/constants";
import { I18N_STRINGS } from "../../../resources/submit";
import { validateField } from "../../common/ValidationRules";

const ActivityInput = React.memo(({ value, index, setItems, prop, options }) => (
  <Autosuggest
    value={value}
    placeholder="Enter an activity type..."
    enteredTextLabel={value => `Use: "${value}"`}
    options={options}
    onChange={({ detail }) => {
      setItems(activities => {
        const updated = [...activities]
        updated[index] = { ...updated[index], [prop]: detail.value }
        return updated;
      })
    }}
  />
));

const DurationInput = React.memo(({ value, index, setItems, prop, options }) => (
  <Autosuggest
    value={value}
    placeholder="Enter a duration in minutes..."
    enteredTextLabel={value => `Use: "${value}"`}
    options={[]}
    onChange={({ detail }) => {
      setItems(activities => {
        const updated = [...activities]
        updated[index] = {
          ...updated[index],
          [prop]: parseInt(detail.value) || 0
        }
        return updated;
      })
    }}
  />
));


export default ({ value, setValue, validationRules }) => {
  const [items, setItems] = useState(value);

  useEffect(() => { setValue(items); }, [items]);

  const onAddButtonClick = useCallback(() => {
    setItems(items => ([ ...items, { activity: "", duration: 30 } ]));
  }, []);

  const onRemoveButtonClick = useCallback(({ detail: { itemIndex } }) => {
    setItems(items => {
      const newItems = items.slice();
      newItems.splice(itemIndex, 1);
      return newItems;
    });
  }, []);

  const definition = useMemo(() => [
    {
      label: 'Activity',
      control: ({ activity="" }, idx) => <ActivityInput prop="activity" value={activity} index={idx} setItems={setItems} options={SUPPORTED_ACTIVITIES.map(act => ({ value: act.label }))} />,
    },
    {
      label: 'Duration (minutes)',
      control: ({ duration=30 }, idx) => <DurationInput prop="duration" value={duration} index={idx} setItems={setItems} options={DEFAULT_DURATIONS} />,
    }
  ], []);


  return (
    <FormField
      label="Choose your activities"
      description="Enter your completed activities with duration. Select a suggested activity from the drop down box or type your own."
      errorText={validateField(validationRules, value)}
      stretch={true}
    >
      <AttributeEditor
        {...I18N_STRINGS}
        items={items}
        definition={definition}
        onAddButtonClick={onAddButtonClick}
        onRemoveButtonClick={onRemoveButtonClick}
      />
    </FormField>
  );
};
